import { type Ref, computed, unref } from "@vue/composition-api";
import { useQuery } from "@tanstack/vue-query";
import { queries } from "@/lib/query/queries";

export function usePersonalTrainingData(memberId: Ref<number>, programId: Ref<number>) {
	const { data: allPersonalTrainingsData } = useQuery({
		...queries.member(memberId.value).personalTraining.all(),
		queryKey: computed(() => queries.member(memberId.value).personalTraining.all().queryKey),
	});

	const personalTraining = computed(() =>
		allPersonalTrainingsData.value?.find(
			(personalTraining) => personalTraining.id === unref(programId),
		),
	);

	const { data: currentTrainingDay } = useQuery({
		...queries.member(memberId.value).personalTraining.single(programId.value).currentDay(),
		queryKey: computed(
			() =>
				queries.member(memberId.value).personalTraining.single(programId.value).currentDay()
					.queryKey,
		),
		enabled: computed(() => !personalTraining.value?.voltooid),
	});

	const { data: allDays } = useQuery({
		...queries.member(memberId.value).personalTraining.single(programId.value).overview(),
		queryKey: computed(
			() =>
				queries.member(memberId.value).personalTraining.single(programId.value).overview().queryKey,
		),
	});

	return computed(() => {
		const id =
			currentTrainingDay.value?.programmaId || personalTraining.value?.id || unref(programId);
		const currentDay = personalTraining.value?.voltooid ? 0 : currentTrainingDay.value?.dag || 1;
		const currentWeek = personalTraining.value?.voltooid ? 0 : currentTrainingDay.value?.week || 1;
		const daysPerWeek = currentTrainingDay.value?.dagenPerWeek || 4;
		const totalWeeks =
			personalTraining.value?.zesWekenProgramma === true ? 6 : Math.max(currentWeek, 1);

		return {
			id,
			name:
				currentTrainingDay.value?.programmaTitel ||
				personalTraining.value?.titel ||
				`Schema ${unref(programId)}`,
			isCurrent: allPersonalTrainingsData.value?.[0]?.id === id,
			isLastDay: currentTrainingDay.value?.laatsteDagVanSchema || false,
			allDaysInWeek: allDays.value?.filter((day) => day.week === currentWeek) || [],
			startedOn: personalTraining.value?.startDatum,
			currentWeek,
			currentDay,
			daysPerWeek,
			totalWeeks,
			completed: personalTraining.value?.voltooid ?? false,
			weeks: Array.from({ length: totalWeeks }, (_, weekIndex) => {
				const week = weekIndex + 1;
				const completedDaysInThisWeek =
					allDays.value?.filter((day) => day.week === week && day.voltooid) || [];

				const days = Array.from({ length: daysPerWeek }, (_, dayIndex) => {
					const day = dayIndex + 1;

					return {
						number: day,
						completed:
							personalTraining.value?.voltooid ||
							completedDaysInThisWeek.some(
								(completedDay) => completedDay.dag === day && completedDay.voltooid,
							),
					};
				});

				return {
					number: week,
					completed: personalTraining.value?.voltooid || days.every((day) => day.completed),
					days,
				};
			}),
		};
	});
}
